*{
    margin: 0;
    padding: 0;
}


@font-face {
    font-family: 'Roboto';
    src: url("./assets/fonts/Roboto-Regular.ttf");
}
@font-face {
    font-family: 'Work Sans',sans-serif;
    src: url("./assets/fonts/WorkSans-SemiBold.ttf");
}
#Wrapper{
    display:flex;
    flex-direction: column;
    align-items: center;
    background-color: lightgray;


}


/* .ButtonEnroll3{margin-top: -100px; float:right;margin-right:50px;} */
.ButtonEnroll3{margin-bottom: 40px;}
.ButtonHeader{position: absolute;}
.ButtonEnroll, .ButtonHeader, .ButtonEnroll3{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    gap: 10px;
    height: 60px;
    background: #FB8C00;
    border-radius: 36px;
    border:none;
    cursor: pointer;
    a{
        width: 143px;
        height: 28px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 28px;
        color: #212121;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
}






.WrapInside{
    @media (max-width:699px) {
        margin-left: 28px;
        #list{span{font-size: 16px;line-height: 24px;}li{justify-content: flex-start;}}
        #Why{p{font-size: 22px;line-height: 44px;}}

        .OrangeBig{font-size: 22px;line-height: 44px;}
        #LvlLabel{font-size: 22px;line-height: 44px;}



        #sprintTextBlock{flex-direction: column;}
        #sprint{ p:last-child{ margin-bottom: -00px;}}


        #CalendarBlock{flex-direction: column-reverse;}
        #LvlLabelWrap{margin-top: 20px;}
    }
    @media (min-width:700px) and (max-width:1440px)  {  

        margin-left: 165px;
        #Why{p{font-size: 32px;line-height: 44px;}}
        #THuman{span{font-size: 3.7vw;line-height: 70px;}}      
        #list{li{justify-content: flex-end;}}
        .OrangeBig{font-size: 32px;line-height: 44px;}
        #LvlLabel{font-size: 3.7vw;line-height: 70px;}} 
        #sprint{    padding-right: 32px;}
        #LvlLabelWrap{margin-top: 20px;}


        @media (min-width:1441px) {
            margin-left: 365px;
            #Why{p{font-size: 2.5vw;line-height: 150%;}}
   
            #list{li{justify-content: flex-end;}}
            .OrangeBig{font-size: 2.5vw;line-height: 150%;}
            #LvlLabel{font-size: 3.7vw;line-height: 70px;}
            #LvlLabelWrap{margin-top: 60px;}
        } 

                }
        





@media (min-width:1441px) {

    .ButtonHeader{bottom:0;left:0;margin-bottom: 93px;margin-left: 365px;
        height: 80px;
        max-width: 500px;width:100%;
    }

    .ButtonEnroll3{margin-left: 365px;max-width: 500px;width: 100%;height:80px;
    }

    #Header2, #Tabs, #ShortTargetTraining, #Whythis, #Teachers, #Price
    {max-width: 2500px;}
    #Header{max-height:980px;max-width:2500px;}
    #Tab-Content{font-size: 1.4vw;line-height: 120%;} 
    #THuman{span{font-size: 3.0vw;line-height: 70px;}}   
    #Header2{
        height: 1000px;
        width: 100%;
        padding-top: 200px;
        #THuman{display: flex;flex-direction: column;justify-content: center;align-items: center;
            img{max-width: 1000px;margin-right: 300px; }
            span{font-size: 4vw;line-height: 70px;margin-left: 365px; margin-bottom: 1050px;position: absolute; left:0;}}}
     

    #list{
        padding-left: 100px;
        li{
            p{font-size: 2vw;line-height: 90%;} 
            span{font-size: 0.9vw;line-height: 130%;}}
            img{padding-bottom:30px;}
        }


        #listTeachers{
            margin-right: 400px;
            max-width: 1722px;
            span{font-size: 1vw;line-height: 130%;}
            p{font-size: 0.9vw;line-height: 130%;}
        }
    
    .ButtonEnroll{max-width: 600px; width:100%; height:80px;}
    #Cetinje{margin-left: 50%;bottom:0;padding-bottom:330px;font-family: 'Work Sans',sans-serif;font-style: normal;font-weight: 700;font-size: 2.5vw;line-height: 44px;}
    #LearnFrom0{margin-left: 365px;margin-top: 130px;font-size: 4vw;line-height: 120px;}
    #ShortTargetTraining{    padding-top: 60px;padding-bottom: 60px;}
    #sprintTextBlock{   margin-top: 34px;padding-bottom: 32px;}
    #CalendartextBlock{     padding-left: 100px;}
/*     #TabDisplay{margin-left: 165px;margin-right: 165px;} */
    
    #Tabs{padding-bottom: 70px; padding-top: 30px; }
    #TargetTrainingSpan{font-size: 2.2vw;line-height: 140%; ;}
    #sprintRightBlock{margin-left: 90px;}
    #sprint{p:first-child{font-size: 2vw;line-height: 130%;}}
    #sprintPS{font-size: 0.8vw;line-height: 90%;max-width: 647px;}
    #sprint{p:not(:first-child){font-size: 0.8vw;line-height: 90%;max-width: 647px;}}   
    #sprintRightBlock{p{font-size: 2vw;line-height: 130%;}}
    #CalendartextBlock{ p{font-size: 1vw;line-height: 30px;}}
    #Pricetable{margin-left: 365px; font-size: 1vw; line-height: 30px;    padding-top: 152px;}
    #zag{font-size: 2vw;line-height: 130%;}
    #zag1{font-size: 2vw;line-height: 130%;    top: -92px; }
    #AOne, #ATwo{font-size:1.2vw;line-height: 130%;

}
  

}

@media (max-width:1441px) {
    #listTeachers{
        max-width: 822px;
    }
    #Header2, #Tabs, #ShortTargetTraining, #Whythis, #Teachers, #Price
        {max-width: 1440px;}
        #Header{max-height:680px;max-width:1440px;}
        #sprint{p:not(:first-child){font-size: 14px;line-height: 26px;        max-width: 447px;}} 
        #sprintPS{font-size: 14px;line-height: 20px;        max-width: 320px;}
        #list{
            li{
                p{font-size: 16px;line-height: 20px;} 
                span{font-size: 20px;line-height: 30px;}}}

    }


a{
    text-decoration: none;
    color:black;
}
  
@media (max-width:699px) {  
    .ButtonEnroll3{height:50px;}
    #THuman{
        padding-top: 27px;
        span{font-size: 22px;font-family: 'Work Sans',sans-serif;line-height: 44px;}} 
    a:hover{
        color:#FB8C00;
    }
    #sprintRightBlock{
        margin-top: 30px;
    }

    .ButtonHeader{height:50px;bottom:0;left:0;margin-bottom: 32px;margin-left: 29px;margin-right: 27px;max-width: 500px;width:88%}

    #Button2EnrollDisplay{margin-bottom: 27px;} 
    .ButtonEnroll{max-width: 500px; width:95%; height:50px;}
    #Cetinje{bottom:0;margin-bottom:37%;right:0;margin-right:36px;font-family: 'Roboto';font-style: normal;font-weight: 400;font-size: 16px;line-height: 24px;}
    #LearnFrom0{margin-left: 28px;margin-top:70px;font-size: 32px;line-height: 48px;}
    #ShortTargetTraining{    padding-top: 20px; }
    #Tab-Content{font-size: 16px;line-height: 24px;}}
    #CalendartextBlock{ padding-right: 27px;padding-bottom: 24px;}
    #Calendar{.react-calendar{max-width: 500px;width:100%;}}
    #Calendar{margin-right: 27px;}
    #sprintTextBlock{   padding-top: 12px;margin-bottom: 20px;}
    #Tabs{padding-bottom:35px;} 
    

    
@media (min-width:700px) and (max-width:1440px) {
    .ButtonHeader{bottom:0;left:0;margin-bottom: 93px;margin-left: 165px;
        max-width: 300px;width:100%;
    }
    .ButtonEnroll{max-width: 500px; width:100%;}
    #Cetinje{margin-left: 486px;bottom:0;margin-bottom: 233px;font-family: 'Work Sans',sans-serif;font-style: normal;font-weight: 600;font-size: 32px;line-height: 44px;}
    #LearnFrom0{margin-left: 165px;margin-top: 130px;font-size: 50px;line-height: 70px;}
    #ShortTargetTraining{    padding-top: 60px; padding-bottom: 40px;}
    #sprintTextBlock{   padding-top: 24px;margin-bottom: 42px;}
    #CalendartextBlock{     padding-left: 30px;}
/*     #TabDisplay{margin-left: 165px;} */
    #Tab-Content{font-size: 20px;line-height: 30px;}
    #THuman{
        padding-top: 50px;
    }
    #Tabs{padding-top: 40px;}

}

@media (max-width:949px) {
    #TargetTrainingSpan{font-size: 22px;line-height: 44px;}
    #sprint{p:first-child{font-size: 18px;line-height: 28px;}}
    #sprintRightBlock{p{font-size: 18px;line-height: 28px;}}
    #CalendartextBlock{ p{font-size: 16px; line-height: 24px;}}
}

@media(max-width:899px){
    #MenuContainerB{
        display: none;
    }

    #Burger{
        position: fixed;
        z-index: 9;
        right: 0;
        margin-top: 16px;
        margin-right: 16px;
        cursor: pointer;
    }
    #MenuContainer{
        position: fixed;
        z-index: 9;
        right: 0;
        margin-top: 12px;
        margin-right: 72px;
        display: grid; 
        align-items: center;
        border-radius: 16px;
        background: #FFF3E0;
        align-items: flex-start;
        gap: 10px;
        span{
            cursor: pointer;
            padding: 8px 16px;
            align-items: flex-start;
            font-size: 20px;
            font-family: 'Roboto';
            line-height: 30px;
            background: var(--white, #FFF);
        }
        span:first-child{
            border-radius: 16px 16px 0px 0px;
        }
        span:last-child{
            border-radius: 0px 0px 16px 16px ;
        }
        span:hover{
            background-color: #FB8C00;
        }
    }
}


@media (min-width:900px){
#MenuContainer{display:none}
    #Burger{display: none;}

#MenuContainerB{
    position: fixed;
    z-index: 9;
    right: 0;
    margin-right: 7%;
    margin-top: 7%;
    cursor: pointer;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 6px;
    padding-right: 7px;
    background-color: rgba(242, 242, 242, 0.5);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap:46px;
    border-radius: 5%;
    a{
        font-size: 20px;
        font-family: 'Roboto';
        line-height: 30px;
    }
    a:hover{
        color: #FB8C00;
    }
}}


@media (min-width:950px) and (max-width:1440px) {



    #TargetTrainingSpan{font-size: 32px;line-height: 44px; ;}
    #sprint{p:first-child{font-size: 28px;line-height: 36px;}}
    #sprintRightBlock{p{font-size: 28px;line-height: 36px;}}
    #CalendartextBlock{ p{font-size: 20px;line-height: 30px;}}

}


@media (max-width:1099px) {
    #Pricetable{margin-left: 28px; font-size: 16px;line-height: 24px;    padding-top: 102px;}
    #zag{font-size: 18px; line-height: 28px;}
    #zag1{font-size: 22px;line-height: 44px;    top: -52px; }
    #ButtonEnroll3Display{margin-left: 28px;margin-right: 27px; }
    .ButtonEnroll3{max-width: 600px; width: 100%}
}
@media (min-width:1100px) and (max-width:1440px) 
{#Pricetable{margin-left: 165px; font-size: 20px; line-height: 30px;    padding-top: 152px;}
#zag{font-size: 28px;line-height: 36px;}
#zag1{font-size: 32px;line-height: 44px;    top: -92px; }
.ButtonEnroll3{margin-left: 165px; max-width: 300px; width: 100%;}
}

#Header{

    position: relative;
    width: 100%;
    height: 100vw;
    #WrapperImg{
        height: 100%;
        width:100%;
        overflow: hidden;
        position: absolute;
    img{
        width:100%;
        height:100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        position: relative;
    }}
    #LearnFrom0{ 
        font-family: 'Work Sans',sans-serif;
        font-style: normal;
        font-weight: 700;

        color: #212121;  
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        position: absolute;
    }
    #Cetinje{
        color: #212121; 
        position: absolute;


    }
}

#Header2{

    display:flex;
    flex-direction: column-reverse;
    background-color: white;
    #THuman{
        span{
            font-family: 'Work Sans',sans-serif;
            font-style: normal;
            font-weight: 700;
            color: #FB8C00;

        }
        img{
            width:100%;
        }  
    }
}


#Whythis{
    background: #F1F8E9;
    width: 100%;
    #Why{
        padding-top: 15px;
        p{
        font-family: 'Work Sans',sans-serif;
        font-style: normal;
        font-weight: 600;
        color: #33691E;}
    }
    #list{
        margin-top: 40px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    li{
        display: flex;
        flex-direction: row;
        margin-bottom: 24px;
    }
    #li1{max-width:658px;span{max-width: 411px;}}
    #li2{max-width:609px;span{max-width: 265px;}}
    #li3{max-width:897px;span{max-width: 361px;}}
    #li4{max-width:958px;span{max-width: 234px;}}
    #li5{max-width:1275px;span{max-width: 360px;}}
        img{width: 68px;height: 72px; border-radius: 50%;} 
    }
    span{
        max-width: 411px;
        min-height: 90px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 150%;
        color: #212121;
        margin-top: -10px;
        padding-left: 16px;
    }

}


#AOne, #ATwo{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;

    color: #FFFFFF;
    text-align: center;
}
#Tab-Content{
    margin-top: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    color: #212121;
}
#Tabs{
    background-color: #D6E1D2 ;

    width: 100%;
    .WrapInside{
        padding-top: 10px;
    }
    #LvlLabel{

        font-family: 'Work Sans',sans-serif;
        font-style: normal;
        font-weight: 700;


        color:#33691E/* #33691E */;
    }
    #TabDisplay{
        display:flex;

    }

}

#ShortTargetTraining{
    display:flex;
    flex-direction: column;

    width: 100%;
    background-color: #FFF3E0;

    .WrapInside{
        @media (min-width:1215px) and (max-width:1440px){
            padding-right: 185px;         
        }
}}
#TargetTrainingSpan{
    font-family: 'Work Sans',sans-serif;
    font-style: normal;
    font-weight: 600;

    color: #FB8C00;
}
#sprintTextBlock{
    display:flex;
    justify-content: left;
}
#sprint{

    p:first-child{
        font-family: 'Work Sans',sans-serif;
        font-style: normal;
        font-weight: 600;
}
    p:not(:first-child){

        padding-top: 12px;
        font-family: 'Roboto';
        font-style: normal;
        padding-bottom: 2px;
        /* font-weight: 400; */}
}
#sprintRightBlock{
    font-family: 'Work Sans',sans-serif;
    font-style: normal;
    font-weight: 600;

}
#CalendarBlock{
    display: flex;

}

button.ColorDatestyle{
    background-color: #FB8C00;
}


#CalendartextBlock{

    p:first-child{
    font-weight: 700;
    margin-bottom: 16px;
}
    p{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;}}
#sprintPS{
    p{
        margin-top: 16px;
    padding-bottom: 30px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
}
}

#Teachers{
    background-color: white;
    width: 100%;
    @media(min-width:650px){
        overflow: hidden;
    }
      overflow-x:scroll;

    #OrbiDis{
        padding-top: 60px;
        margin-bottom: 32px;
    }
    .OrangeBig{
        font-family: 'Work Sans',sans-serif;
        font-style: normal;
        font-weight: 600;
        color: #FB8C00;
    }
    img{width: 120px; height: 120px;}
    #listTeachers{
        display: flex;
        flex-direction: row;
        justify-content: left;
    :first-child{
        padding-right: 7%;
    }
        padding-bottom: 60px;
        li:not(:last-child) {
            margin-right: 30px;
          }
        li{
            display: flex;
            flex-direction: column;
            align-items: center;
            p{
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                text-align: center;
                color: #212121;
            }
            span{
                padding-top: 10px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                color: #212121;
            }
        }
    }
}

#Price{
    background-color: #FFEB3B;
    width: 100%;
    overflow-x: scroll;
    @media(min-width:650px){
        overflow: hidden;
    }
}
#subscription, #one-time, #trial, #rate{
    white-space: nowrap;
}
#zag{
    font-family: 'Work Sans',sans-serif;
    font-style: normal;
    font-weight: 600;
}
#zag1{
    position: relative;
    font-family: 'Work Sans',sans-serif;
    font-style: normal;
    font-weight: 600;
}
#Pricetable {
    display: grid;
    grid-template-columns: auto repeat(5, 1fr);
    gap: 0;
    grid-auto-rows: min-content;
    padding-bottom: 60px;
  }
#subscription, #one-time, #trial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #rate{
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;
  }
  
  #Pricetable p {
    padding: 5px;
  }